define("discourse/plugins/discourse-algolia/discourse/api-initializers/discourse-algolia", ["exports", "@ember/render-modifiers/modifiers/did-insert", "rsvp", "discourse/lib/api", "discourse/lib/load-script", "discourse/lib/url", "discourse-common/config/environment", "I18n", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _didInsert, _rsvp, _api, _loadScript, _url, _environment, _I18n, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function initializeAutocomplete(options1) {
    const algoliasearch1 = window.algoliasearch;
    const {
      autocomplete: autocomplete1,
      getAlgoliaResults: getAlgoliaResults1
    } = window["@algolia/autocomplete-js"];
    const autocompleteElement1 = document.getElementsByClassName("algolia-search")[0].getElementsByClassName("aa-Autocomplete");
    if (autocompleteElement1.length > 0) {
      return;
    }
    const searchClient1 = algoliasearch1(options1.algoliaApplicationId, options1.algoliaSearchApiKey);
    const hitsPerPage1 = 4;
    const autocompleteSearch1 = autocomplete1({
      container: ".algolia-search",
      panelContainer: ".algolia-autocomplete",
      debug: options1.debug,
      detachedMediaQuery: "none",
      placeholder: _I18n.default.t("discourse_algolia.search_box_placeholder"),
      getSources() {
        return [{
          sourceId: "posts",
          getItemInputValue: _ref => {
            let {
              item: item1
            } = _ref;
            return item1.query;
          },
          getItems(_ref2) {
            let {
              query: query1
            } = _ref2;
            return getAlgoliaResults1({
              searchClient: searchClient1,
              queries: [{
                indexName: "discourse-posts",
                query: query1,
                params: {
                  hitsPerPage: hitsPerPage1
                }
              }]
            });
          },
          templates: {
            item(_ref3) {
              let {
                item: item1,
                components: components1,
                html: html1
              } = _ref3;
              let tags1 = [];
              let baseTags1 = item1.topic.tags;
              if (baseTags1) {
                baseTags1.forEach((baseTag1, index1) => {
                  tags1.push(html1`<a
                    class="hit-post-tag"
                    onClick="${event1 => {
                    _url.default.routeTo(`/tags/${baseTag1}`);
                    autocompleteSearch1.setIsOpen(false);
                    event1.preventDefault();
                    event1.stopPropagation();
                  }}"
                  >
                    ${components1.Highlight({
                    hit: item1,
                    attribute: ["topic", "tags", index1]
                  })}
                  </a>`);
                });
              }
              return html1` <div class="hit-post">
                <div class="hit-post-title-holder">
                  <span class="hit-post-topic-title">
                    ${components1.Highlight({
                hit: item1,
                attribute: ["topic", "title"]
              })}
                  </span>
                  <span
                    class="hit-post-topic-views"
                    title="${_I18n.default.t("discourse_algolia.topic_views")}"
                  >
                    ${item1.topic.views}
                  </span>
                </div>
                <div class="hit-post-category-tags">
                  <span class="hit-post-category">
                    <span class="badge-wrapper bullet">
                      <span
                        class="badge-category-bg"
                        style="background-color: #${item1.category?.color};"
                      />
                      <a
                        class="badge-category hit-post-category-name"
                        onClick="${event1 => {
                _url.default.routeTo(item1.category.url);
                autocompleteSearch1.setIsOpen(false);
                event1.preventDefault();
                event1.stopPropagation();
              }}"
                        >${item1.category?.name}</a
                      >
                    </span>
                  </span>
                  <span class="hit-post-tags">${tags1}</span>
                </div>
                <div class="hit-post-content-holder">
                  <a
                    class="hit-post-username"
                    onClick="${event1 => {
                _url.default.routeTo(item1.user.url);
                autocompleteSearch1.setIsOpen(false);
                event1.preventDefault();
                event1.stopPropagation();
              }}"
                    >@${item1.user.username}</a
                  >:
                  <span class="hit-post-content">
                    ${components1.Snippet({
                hit: item1,
                attribute: "content"
              })}
                  </span>
                </div>
              </div>`;
            },
            noResults(_ref4) {
              let {
                html: html1
              } = _ref4;
              return html1`<div class="aa-empty">
                ${_I18n.default.t("discourse_algolia.no_posts")}
              </div>`;
            }
          },
          onSelect(_ref5) {
            let {
              item: item1
            } = _ref5;
            _url.default.routeTo(item1.url);
          }
        }, {
          sourceId: "users",
          getItemInputValue: _ref6 => {
            let {
              item: item1
            } = _ref6;
            return item1.query;
          },
          getItems(_ref7) {
            let {
              query: query1
            } = _ref7;
            return getAlgoliaResults1({
              searchClient: searchClient1,
              queries: [{
                indexName: "discourse-users",
                query: query1,
                params: {
                  hitsPerPage: hitsPerPage1
                }
              }]
            });
          },
          templates: {
            item(_ref8) {
              let {
                item: item1,
                components: components1,
                html: html1
              } = _ref8;
              let likesElement1 = "";
              if (item1.likes_received > 0) {
                likesElement1 = html1`<span class="hit-user-like-heart">❤</span>
                  ${item1.likes_received}`;
              }
              const usernameElement1 = components1.Highlight({
                hit: item1,
                attribute: item1.name ? "name" : "username"
              });
              return html1`<div class="hit-user-left">
                  <img
                    class="hit-user-avatar"
                    src="${options1.imageBaseURL}${item1.avatar_template.replace("{size}", 50)}"
                  />
                </div>
                <div class="hit-user-right">
                  <div class="hit-user-username-holder">
                    <span class="hit-user-username">
                      @${components1.Highlight({
                hit: item1,
                attribute: "username"
              })}
                    </span>
                    <span
                      class="hit-user-custom-ranking"
                      title="${_I18n.default.t("discourse_algolia.user_likes")}"
                    >
                      ${likesElement1}
                    </span>
                  </div>
                  <div class="hit-user-name">${usernameElement1}</div>
                </div>`;
            }
          },
          onSelect(_ref9) {
            let {
              item: item1
            } = _ref9;
            _url.default.routeTo(item1.url);
          }
        }, {
          sourceId: "tags",
          getItemInputValue: _ref10 => {
            let {
              item: item1
            } = _ref10;
            return item1.query;
          },
          getItems(_ref11) {
            let {
              query: query1
            } = _ref11;
            return getAlgoliaResults1({
              searchClient: searchClient1,
              queries: [{
                indexName: "discourse-tags",
                query: query1,
                params: {
                  hitsPerPage: hitsPerPage1
                }
              }]
            });
          },
          templates: {
            item(_ref12) {
              let {
                item: item1,
                components: components1,
                html: html1
              } = _ref12;
              return html1`<div class="hit-tag">
                #<span class="hit-tag-name">
                  ${components1.Highlight({
                hit: item1,
                attribute: "name"
              })}</span
                >
                <span
                  class="hit-tag-topic_count"
                  title="${_I18n.default.t("discourse_algolia.topic_tags")}"
                  >${item1.topic_count}</span
                >
              </div> `;
            }
          },
          onSelect(_ref13) {
            let {
              item: item1
            } = _ref13;
            _url.default.routeTo(item1.url);
          }
        }];
      },
      render(_ref14, root1) {
        let {
          elements: elements1,
          render: render1,
          html: html1
        } = _ref14;
        const {
          posts: posts1,
          users: users1,
          tags: tags1
        } = elements1;
        render1(html1`<div class="aa-dropdown-menu">
          <div class="left-container">
            <div class="aa-dataset-posts">${posts1}</div>
          </div>
          <div class="right-container">
            <span class="aa-dataset-users">${users1}</span>
            <span class="aa-dataset-tags">${tags1}</span>
          </div>
          <div class="aa-footer">
            <div class="left-container">
              <a
                class="advanced-search"
                onClick="${event1 => {
          _url.default.routeTo("/search");
          autocompleteSearch1.setIsOpen(false);
          event1.preventDefault();
          event1.stopPropagation();
        }}"
                >${_I18n.default.t("discourse_algolia.advanced_search")}</a
              >
            </div>
            <div class="right-container">
              <a
                target="_blank"
                class="algolia-logo"
                href="https://algolia.com/"
                title="${_I18n.default.t("discourse_algolia.powered_by")}"
              ></a>
            </div>
          </div>
        </div>`, root1);
      }
    });
    return autocompleteSearch1;
  }
  var _default = _exports.default = (0, _api.apiInitializer)("0.8", api => {
    const siteSettings = api.container.lookup("service:site-settings");
    const currentUser = api.getCurrentUser();
    const shouldDisplay = () => siteSettings.algolia_enabled && siteSettings.algolia_autocomplete_enabled && (!siteSettings.login_required || currentUser);
    let search;
    function renderAlgolia() {
      search?.destroy();
      _rsvp.Promise.all([(0, _loadScript.default)("/plugins/discourse-algolia/javascripts/autocomplete.js"), (0, _loadScript.default)("/plugins/discourse-algolia/javascripts/algoliasearch.js")]).then(() => {
        document.body.classList.add("algolia-enabled");
        search = initializeAutocomplete({
          algoliaApplicationId: siteSettings.algolia_application_id,
          algoliaSearchApiKey: siteSettings.algolia_search_api_key,
          imageBaseURL: "",
          debug: (0, _environment.isDevelopment)()
        });
      });
    }
    api.headerIcons.add("algolia", (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if (shouldDisplay)}}
          <li class="algolia-holder" {{didInsert renderAlgolia}}>
            <div class="algolia-search"></div>
            <div class="algolia-autocomplete"></div>
          </li>
        {{/if}}
      
    */
    {
      "id": "xi8enSRY",
      "block": "[[[1,\"\\n\"],[41,[28,[32,0],null,null],[[[1,\"      \"],[11,\"li\"],[24,0,\"algolia-holder\"],[4,[32,1],[[32,2]],null],[12],[1,\"\\n        \"],[10,0],[14,0,\"algolia-search\"],[12],[13],[1,\"\\n        \"],[10,0],[14,0,\"algolia-autocomplete\"],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-algolia/discourse/api-initializers/discourse-algolia.js",
      "scope": () => [shouldDisplay, _didInsert.default, renderAlgolia],
      "isStrictMode": true
    }), (0, _templateOnly.default)(undefined, undefined)));
  });
});